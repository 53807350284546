import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Accueil.vue'
import event from '../views/Appshop.vue'
import ContactUsForm from '../views/ContactUsForm.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Connexion from '../views/Connexion.vue'
import Achatticket from '../views/AchatTicket.vue'
import DownloadTicket from '../views/DownloadTicket.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/evenements',
    name: 'evenements',
    component: event,
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: ContactUsForm
  },

  {
    path: '/connexion',
    name: 'connexion',
    component: Connexion
  },

  {
    path: '/achatticket/:eventslug',
    name: 'achatticket',
    component: Achatticket,
    props: true
  },
    {
    path: '/downloadTicket/:eventslug',
    name: 'downloadTicket',
    component: DownloadTicket,
    props: true
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  }
]

//const base = document.getElementsByTagName('base')[0].href
const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  base: '/',
  routes
})

export default router
