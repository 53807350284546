try {
  window.$ = window.jquery = require('jquery')
  require('foundation-sites/dist/js/foundation')
  require('materialize-css/js/global')

 
 

} catch (e) {
  alert(e.message)
}