<template>
    <section id="purchase">
        <div class="grid-container">
            <div class="grid-x" v-if="this.product">
                <div class="cell small-12 medium-12 large-6">
                    <div
                        class="leftcontent"
                        
                    >
                        <img :src="product.imageUrl" alt="" />
                    </div>
                </div>
                <div class="cell small-12 medium-12 large-6">
                    <div class="content">
                        <div class="theform">
                            <h2><span>ticket</span></h2>
                            <p>{{ product.name }}</p>
                            <div style="display: flex" class="info">
                                <p>
                                    <img
                                        src="@/assets/images/location.svg"
                                        alt=""
                                    />

                                    {{ product.location }}
                                </p>
                                <p>
                                    <img
                                        src="@/assets/images/date.svg"
                                        alt=""
                                    />
                                    {{ product.date }}
                                </p>
                            </div>
                            <form @submit.prevent="submitForm">
                                <div class="theme_form">
                                    <div class="form_item">
                                        <select
                                            v-model="nombre"
                                            required
                                            class="form_control"
                                        >
                                            <option value="" disabled selected>
                                                Choisir le nombre de ticket
                                            </option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>
                                    <div class="form_item">
                                        <select
                                            v-model="type"
                                            required
                                            class="form_control"
                                        >
                                            <option value="" disabled selected>
                                                Choisir le type de ticket
                                            </option>
                                            <option
                                                v-for="ticket in product.tickets"
                                                :key="ticket.id"
                                                :value="ticket.id"
                                            >
                                                {{ ticket.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form_item">
                                        <input
                                            class="form_control"
                                            placeholder="Nom et Prénom *"
                                            type="name"
                                            required
                                            v-model="nom"
                                        />
                                    </div>
                                    <div class="form_item">
                                        <vue-tel-input
                                            v-model="telephone"
                                        ></vue-tel-input>
                                    </div>
                                    <div class="form_item">
                                        <input
                                            class="form_control"
                                            placeholder="Adresse email"
                                            type="email"
                                            v-model="email"
                                        />
                                    </div>

                                    <div style="height: 25px"></div>
                                    <div class="form_button">
                                        <button class="btn_cutom" type="submit">
                                            <svg>
                                                <rect
                                                    x="0"
                                                    y="0"
                                                    fill="none"
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </svg>
                                            Valider
                                        </button>
                                        <div style="height: 120px"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { httpClient } from "@/service/http";
import { sleep } from "@/service/utils";
import { apiUrl, httpRetryInterval } from "@/service/const";
import axios from "axios";

export default {
    name: "Achatticket",
    props: ["_product", "eventslug"],
    created() {
        // document.title = `PayTick`;
    },
    data() {
        return {
            product: null,
            type: "",
            nombre: "",
            nom: "",
            email: "",
            telephone: "",
            adresse: ""
        };
    },

    async mounted() {
        if (!this._product) {
            await this.fetchProductBySlug(this.eventslug);
        } else {
            this.product = this._product;
        }
        document.title = `Acheter ticket ${this.product.name}`;
        console.log(this.product, this.eventslug);
    },
    methods: {
        async fetchProductBySlug(productSlug) {
            return new Promise(resolve => {
                (async () => {
                    try {
                        const response = await httpClient.get(
                            `/event-slug/${this.eventslug}`
                        );
                        console.log(response);
                        const prod = response.data;
                        this.product = prod;
                        resolve(prod);
                        if (!prod.id) {
                            this.$router.push({ name: "PageNotFound" }).then();
                        }
                    } catch (e) {
                        await sleep(httpRetryInterval);
                        this.fetchProductBySlug(productSlug).then();
                    }
                })();
            });
        },
        async submitForm() {
            const PayTech = window["PayTech"];

            this.$swal.showLoading();

            // v21 start
            try {
                const response = await httpClient.get(
                    `/ticket_type/${this.type}`
                );
                console.log(response);

                if (response.status == 200) {
                    if (response.data == "free") {
                        console.log(response.data);
                        axios
                            .post(apiUrl + "/ticket_free_payment", {
                                type: this.type,
                                nombre: this.nombre,
                                nom: this.nom,
                                email: this.email || "",
                                telephone: this.telephone.replace(/ /g, ""),
                                adresse: this.adresse || ""
                            })
                            .then(response =>
                                response.data.success
                                    ? window.location.replace(
                                          response.data.data
                                      )
                                    : this.alert2(response.data.message)
                            )
                            .catch(error => console.log(error, "catch error"));
                    } else {
                        console.log("not free");
                        new PayTech({
                            //_token:	"3gZfrwRBqTMNeCxFxQSdIQoxmhh9VEhCVfI9DhZa",
                            type: this.type,
                            nombre: this.nombre,
                            nom: this.nom,
                            email: this.email || "",
                            telephone: this.telephone.replace(/ /g, ""),
                            adresse: this.adresse || ""
                        })
                            .withOption({
                                requestTokenUrl: apiUrl + "/buyTicket",
                                method: "post",
                                headers: {
                                    Accept: "text/html"
                                },
                                prensentationMode: PayTech.OPEN_IN_POPUP,
                                willGetToken: () => {
                                    console.log("will get token: *******");
                                },
                                didGetToken: (token, redirectUrl) => {
                                    this.$swal.close();
                                    console.log("did Get Token: *******");
                                    console.log(token, redirectUrl, this.$swal);
                                },
                                didReceiveError: error => {
                                    this.alert2(JSON.stringify(error));
                                    //  $('#modalalerte').modal('show');
                                    console.log(
                                        "didReceiveError, erreur ",
                                        error
                                    );
                                },
                                didReceiveNonSuccessResponse: jsonResponse => {
                                    this.alert2(jsonResponse.errors[0]);
                                    console.log(
                                        "didReceiveNonSuccessResponse: *******",
                                        jsonResponse
                                    );
                                }
                            })
                            .send();
                    }
                }
            } catch (e) {
                await sleep(httpRetryInterval);
            }
            // v21 end
        },
        async alert2(msg, title) {
            msg = String(msg);
            this.$swal({
                text: msg,
                title: title || "Informations",
                icon: "info",
                dangerMode: true
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/partials/global";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

#purchase {
    padding-bottom: 50px;
    padding-top: 50px;
    min-height: 100vh;
    //   display: flex;
    align-items: center;
    justify-content: center;
    //   background: #fafafa;
    //   background-image: url(../images/auth-bg.jpg);
    background-repeat: no-repeat;
    position: relative;

    .innerpage-decor {
        .innerpage-circle1 {
            position: absolute;
            left: 247px;
            bottom: 250px;
        }

        .innerpage-circle2 {
            position: absolute;
            left: 50px;
            top: auto;
            bottom: 20px;
        }
    }

    .leftcontent {
        height: 100%;
        border-top: 3px solid $primary-color;
        border-bottom: 3px solid $primary-color;
        background-size: cover;

        img {
            // height: 100%;

            @include breakpoint(mobileonly) {
                height: auto;
            }
            object-fit: fill;
            // width: 100%;
            // height: 100%;
        }
    }

    .content {
        h2 {
            line-height: 28px;
            font-size: 30px;
            font-weight: 300;
            font-family: "Poppins", sans-serif;
            margin-bottom: 20px;
            color: #000000;
            text-transform: uppercase;
            letter-spacing: 4px;
            text-align: center;
        }

        p {
            width: 70%;
            margin: 0 auto 30px;
            font-family: "Poppins", sans-serif;
            line-height: 1.8em;
            font-size: 15px;
            color: #000000;
            letter-spacing: 0.03em;
            text-transform: capitalize;
            text-align: center;
        }

        .theform {
            border: 0;
            border-top: 3px solid $primary-color;
            border-bottom: 3px solid $primary-color;
            padding: 28px 50px 50px;
            //   margin: 0 auto;
            border-radius: 0;
            color: #ffffff;
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: rgba($color: #fafafa, $alpha: 1);
            background-clip: border-box;

            .theme_form {
                .form_item {
                    margin: 20px auto;
                    margin-bottom: 30px;
                    //   display: flex;
                    //   justify-content: center;
                    //   align-items: center;

                    .form_control {
                        display: block;
                        width: 100%;
                        -webkit-box-shadow: 0 0 5px 0 rgb(170 170 170 / 35%);
                        box-shadow: 0 0 5px 0 rgb(170 170 170 / 35%);
                        font-size: 12px;
                        border-radius: 20px;
                        border: 1px solid #ced4da;
                        padding: 12px 30px;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        height: 45px;
                    }
                }

                .form_button {
                    text-align: center;

                    .btn_cutom {
                        background-color: #ffffff;
                        color: $primary-color;
                        font-size: 14px;
                        border-radius: 3px;
                        text-transform: uppercase;
                        padding: 10px 25px;
                        cursor: pointer;
                        border: none;
                        background-size: 300% 100%;
                        -webkit-transition: all 0.4s ease-in-out;
                        transition: all 0.4s ease-in-out;
                        position: relative;
                        // background: darken($primary-color, 1.5%);
                        // font-weight: 100;

                        svg {
                            height: 35px;
                            left: 0;
                            position: absolute;
                            top: 0;
                            width: 100%;
                        }

                        rect {
                            fill: none;
                            stroke: $primary-color;
                            stroke-width: 2;
                            stroke-dasharray: 422, 0;
                            transition: all 0.35s linear;
                        }

                        &:hover {
                            background: rgba($primary-color, 0);
                            font-weight: 900;
                            letter-spacing: 1px;

                            rect {
                                stroke-width: 5;
                                stroke-dasharray: 15, 310;
                                stroke-dashoffset: 48;
                                transition: all 1.35s
                                    cubic-bezier(0.19, 1, 0.22, 1);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
