var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"buttonn",attrs:{"to":{
            name: 'achatticket',

            params: {
             _product: _vm.product,
              eventslug: _vm.product.slug,
            },
          }}},[_c('div',{staticClass:"thenewcard"},[_c('img',{attrs:{"src":_vm.product.imageUrl,"alt":""}}),_c('div',{staticClass:"eventbutton"},[_vm._v("Acheter")])])])}
var staticRenderFns = []

export { render, staticRenderFns }