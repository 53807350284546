<template>
  <header>
    <AppMobileNav />
    <section id="top-nav" class="show-for-large">
      <div>
        <div class="grid-x align-middle">
          <div class="large-12 cell">
            <div id="navbar">
              <router-link to="/"
                ><img
                  class="logo"
                  src="@/assets/images/LOGO_PAYTICK.png"
                  alt=""
              /></router-link>
              <nav>
                <ul class="menu">
                  <li
                    class="menu-item"
                    @click="onItemclicked('A')"
                    :class="{ activemenu: activemenu }"
                  >
                    <router-link to="/">Accueil</router-link>
                  </li>
                  <li
                    class="menu-item"
                    @click="onItemclicked('B')"
                    :class="{ activemenu: activemenuB }"
                  >
                    <router-link to="/evenements">Evènements</router-link>
                  </li>
                  <!-- <li class="menu-item">
                    <a href="#work">Comment acheter ?</a>
                  </li> -->
                  <!-- <li class="menu-item"><a href="#projects">FAQ</a></li> -->
                  <li
                    class="menu-item"
                    @click="onItemclicked('C')"
                    :class="{ activemenu: activemenuC }"
                  >
                    <router-link to="/contactus">Contact</router-link>
                  </li>
                  <li
                    class="menu-item"
                    @click="onItemclicked('D')"
                    :class="{ activemenu: activemenuD }"
                  >
<!--
                    <router-link to="/connexion">Connexion</router-link>
-->
                    <a  :href="backOfficeUrl + '/login'">Connexion</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section id="main-nav" class="show-for-large">
      <div class="grid-container">
        <div class="grid-x align-center">
          <div class="large-4 small-4 cell logo-container">
            <img src="@/assets/images/LOGO_PAYTICK.png" alt="" />
          </div>
          <div class="large-8 small cell">
            <nav>
              <ul class="menu">
                <li class="menu-item active-menu">
                  <router-link to="/">Accueil</router-link>
                </li>
                <li class="menu-item">
                  <router-link to="/evenements">Evènements</router-link>
                </li>
                <li class="menu-item"><a href="#work">Comment acheter ?</a></li>
                <li class="menu-item"><a href="#projects">FAQ</a></li>
                <li class="menu-item">
                  <router-link to="/about">About</router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section> -->
  </header>
</template>

<script>
import AppMobileNav from "@/components/AppMobileNav.vue";
import {backOfficeUrl} from "@/service/const";
export default {
  name: "AppHeader",
  components: { AppMobileNav },
  data() {
    return {
      activemenu: true,
      activemenuB: false,
      activemenuC: false,
      activemenuD: false,
        backOfficeUrl: backOfficeUrl
    };
  },
  methods: {
    myFilter: function () {
      this.activemenu = !this.activemenu;
      // some code to filter users
    },
    onItemclicked(box) {
      if (box === "A") {
        this.activemenu = true;
        this.activemenuB = false;
        this.activemenuC = false;
        this.activemenuD = false;
      }
      if (box === "B") {
        this.activemenuB = true;
        this.activemenu = false;
        this.activemenuC = false;
        this.activemenuD = false;
      }
      if (box === "C") {
        this.activemenuC = true;
        this.activemenu = false;
        this.activemenuB = false;
        this.activemenuD = false;
      }
      if (box === "D") {
        this.activemenuD = true;
        this.activemenu = false;
        this.activemenuB = false;
        this.activemenuC = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

// #main-nav {
//   font-family: "Montserrat", sans-serif;
//   background: #9c49e5;

//   .active-menu {
//     a {
//       background: white;
//       color: #9c49e5 !important;
//     }
//   }

//   .logo-container {
//     padding: 15px 0;
//     text-align: center;

//     img {
//       width: 40%;
//     }
//   }

//   nav {
//     background: transparent;
//     box-shadow: none;
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//   }

//   .menu {
//     .menu-item {
//       //margin: 15px;
//       a {
//         padding: 20px;
//         color: white;
//         transition: all 0.2s ease-in-out;

//         &:hover {
//           background: white;
//           color: #9c49e5;
//         }
//       }
//     }
//   }
// }

#top-nav {
  background: transparent;
  // background: #9c49e5;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  padding: 0 5%;
  #navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    text-align: right;

    .logo {
      width: 150px;
      cursor: pointer;
      margin: 30px 0;
    }

    ul li {
      list-style: none;
      display: inline-block;
      margin-right: 30px;
    }

    // .activemenu {
    //   a {
    //     background: rgba($color: #ffffff, $alpha: 0.9);
    //     color: #000000 !important;
    //   }
    // }
    .menu {
      .menu-item {
        //margin: 15px;
        a {
          padding: 15px;
          color: white;
          transition: all 0.2s ease-in-out;
          border-radius: 5px;

          &:hover {
            background: #fafafa;
            color: #000000;
          }
        }
      }
    }
  }
}
</style>
