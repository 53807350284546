<template>
  <footer>
    <!-- <AppContact /> -->
    <section id="smallfooter">
      <div class="grid-container">
        <div class="grid-x grid-margin-x">
          <div class="cell small-12 medium-4 large-4 align-center">
            <div class="contact-container">
              <div class="iconcontainer">
                <p><b-icon icon="phone" size="is-small"> </b-icon> :</p>
                <!-- <p><b-icon icon="email" size="is-small"> </b-icon> :</p> -->
              </div>
              <div class="numero">
                <p>+221 77 218 99 19 / +221 33 825 90 80</p>
                <!-- <p>contact@paytick.sn</p> -->
              </div>
            </div>
            <!-- <div class="contact-container">
              <div class="iconcontainer">
                <p><b-icon icon="email" size="is-small"> </b-icon> :</p>
              </div>
              <div class="numero">
                <p>contact@paytick.sn</p>
              </div>
            </div> -->
          </div>
          <div class="cell small-12 medium-4 large-4 align-center">
            <div class="contact-container">
              <div class="iconcontainer">
                <!-- <p><b-icon icon="phone" size="is-small"> </b-icon> :</p> -->
                <p><b-icon icon="email" size="is-small"> </b-icon> :</p>
              </div>
              <div class="numero">
                <!-- <p>+221 77 218 99 19 / +221 33 825 90 80</p> -->
                <p>contact@paytick.sn</p>
              </div>
            </div>
            <!-- <div class="contact-container">
              <div class="iconcontainer">
                <p><b-icon icon="email" size="is-small"> </b-icon> :</p>
              </div>
              <div class="numero">
                <p>contact@paytick.sn</p>
              </div>
            </div> -->
          </div>
          <div class="cell small-12 medium-4 large-4">
            <div class="logo-container">
              <div class="copyright">
                <p>© 2022 Paytick — Tous droits réservés</p>
              </div>
            </div>
          </div>
          <!-- <div class="cell small-12 medium-2 large-4">
            <div class="logo-container">
              <img src="@/assets/images/LOGO_PAYTICK.png" alt="" />
            </div>
          </div> -->
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
// import AppContact from "./AppContact.vue";

export default {
  name: "AppFooter",

  components: {
    // AppContact,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/partials/global";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");



#main-nav {
  font-family: "Montserrat", sans-serif;

  background: #9c49e5;

  .active-menu {
    a {
      background: white;
      color: #9c49e5 !important;
    }
  }

  .logo-container {
    padding: 15px 0;
    text-align: center;

    img {
      width: 10%;
    }
  }

  nav {
    background: transparent;
    box-shadow: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .menu {
    .menu-item {
      //margin: 15px;
      a {
        padding: 20px;
        color: white;
        transition: all 0.2s ease-in-out;

        &:hover {
          background: white;
          color: #9c49e5;
        }
      }
    }
  }
}

#top-nav {
  .logo-container {
    padding: 15px 0;
    text-align: center;
  }
}

#smallfooter {
  background-color: $primary-color;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 105x;

  .contact-container {
    color: #ffffff;
    text-align: center;
    display: flex;
    justify-content: center;
    text-align: center;

    align-items: center;

    .numero {
      margin: 0 0 0 10px;
      p {
        color: #ffffff;
      }
    }
  }

  .logo-container {
    @include breakpoint(mobileonly) {
      text-align: center;
    }

    img {
      width: 40%;
    }
  }

  .copyright {
    text-align: center;
    font-weight: 300;
    color: white;

    padding: 10px;
  }
}
</style>