import Vue from 'vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import VueHead from 'vue-head'
// import VuePhoneNumberInput from 'vue-phone-number-input';
// import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import MenuIcon from 'vue-material-design-icons/Menu.vue';

import store from './store'
import App from './App.vue'
import './bootstrap'



import "../node_modules/materialize-css/js/global";
// import "../node_modules/materialize-css/js/sidenav";
import './custom'
import router from './router'

Vue.config.productionTip = false
Vue.use(VueHead)

Vue.use(Buefy)
Vue.component('menu-icon', MenuIcon);

Vue.use(VueTelInput, {
  defaultCountry: "sn",
    invalidMsg: 'Numéro de téléphone incorrect',
    mode: 'international',
    inputOptions:{
        showDialCode: false,
        placeholder:"Numéro de téléphone",
        required: true,
    },
  //onlyCountries: ["sn"],
  autoDefaultCountry: true,
    dropdownOptions: {
        showDialCodeInList: false,
        showDialCodeInSelection: true
    }
});


Vue.use(VueSweetalert2);



new Vue({

  router,
  store,
  render: h => h(App)
}).$mount('#app')
