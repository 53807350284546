<template>
    <section class="home-banner-section">
        <slick
            v-if="topProducts.length > 0"
            ref="slick"
            :options="slickOptions"
        >
            <div v-for="product in topProducts" :key="product.slug">
                <section class="wrapthis">
                    <div class="secondeven">
                        <router-link
                            class="buttonn"
                            :to="{
                                name: 'achatticket',
                                params: {
                                    _product: product,
                                    eventslug: product.slug
                                }
                            }"
                        >
<!--                            <img
                                style="height: 250px;object-fit: cover"
                                :src="product.imageUrl"
                                alt=""
                            />-->
                            <img
                                style="height: 500px;object-fit: cover"
                                :src="product.imageUrl"
                                alt=""
                            />
                            <button class="button-64" role="button">
                                <span class="text"
                                    >Achetez votre ticket maintenant</span
                                >
                            </button>
                        </router-link>
                    </div>
                </section>
            </div>

            <!-- <div class="secondeven">
        <a href="#">
          <img src="@/assets/images/visuajfss.png" alt="" />

          <button class="button-64" role="button"><span class="text">Achetez votre ticket maintenant</span></button>
        </a>
      </div> -->
            <!-- <a target="_blank" href="https://ajfss.paytick.sn/">
        <img src="@/assets/images/visuajfss.png" alt />
      </a>
      <a target="_blank" href="">
        <img src="@/assets/images/banthree.png" alt />
      </a> -->
            <!-- <img src="@/assets/images/banone.png" alt /> -->
            <!-- <img src="@/assets/images/bantwo.png" alt /> -->
        </slick>
    </section>
</template>

<script>
import Slick from "vue-slick";
import { httpClient } from "@/service/http";
import { sleep } from "@/service/utils";
import { httpRetryInterval } from "@/service/const";
export default {
    name: "AppBanner",
    components: { Slick },
    data() {
        return {
            topProducts: [],
            slickOptions: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                horizontal: true,
                //dots: true,
                //centerMode: true,
                autoplay: true,
                arrows: false,
                // centerPadding: "60px",
                // adaptiveHeight: true,
                mobileFirst: true,
                pauseOnFocus: false,
                pauseOnHover: false

                // Any other options that can be got from plugin documentation
            }
        };
    },

    mounted() {
        this.fetchTopProducts();
    },

    methods: {
        async fetchTopProducts() {
            return new Promise(resolve => {
                (async () => {
                    try {
                        const response = await httpClient.get(`/popular-event`);
                        const prods = response.data;
                        this.topProducts = prods;

                        resolve(prods);
                    } catch (e) {
                        console.log(e);
                        await sleep(httpRetryInterval);
                        this.fetchTopProducts().then();
                    }
                })();
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/partials/global";

.wrapthis {
    position: relative;
}

.home-banner-section {
    .secondeven {
        //border: rgba(255, 255, 255, .2)1px solid;
        text-align: center;
        // margin: 20px 0;
        transition: all 0.3s;

        &:hover {
            img {
                opacity: 0.9;
                box-shadow: none;
            }
        }

        img {
            transition: all 0.3s;

            box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
                rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
            min-width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
        }

        h3 {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            padding-top: 0.5em;
            padding-bottom: 0.5em;
            margin-top: 0;
            color: #ffffff;
            font-weight: 500;
        }

        p {
            font-size: 1.65em;
            font-weight: 700;
            color: #ffffff;
        }

        .button-64 {
            -webkit-appearance: none;
            position: absolute;
            bottom: -21px;

            left: 50%;
            background: $primary-color;
            font-weight: bold;
            transform: translateX(-50%) translateY(-50%);
            align-items: center;
            //margin: -15px auto 0 auto;
            //background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB);
            border: 0;
            border-radius: 8px;
            box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
            box-sizing: border-box;
            color: #000000;
            display: flex;
            font-family: Phantomsans, sans-serif;
            font-size: 16px;
            justify-content: center;
            line-height: 1em;
            max-width: 100%;
            min-width: 140px;
            padding: 3px;
            text-decoration: none;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            white-space: nowrap;
            cursor: pointer;
            box-shadow: 0px 0px 33px 16px #000000;

            //                @include breakpoint(mobileonly) {
            //                    position: none;
            //                    bottom: -28px;
            //                    right: 30px;
            //                    padding: 3px;
            //                    font-size: 10px;
            //
            //                }

            @include breakpoint(mobileonly) {
                font-size: 13px;
            }
        }

        .button-64:active,
        .button-64:hover {
            outline: 0;
        }

        .button-64 span {
            // background-color: rgb(5, 6, 45);
            padding: 10px 15px;
            border-radius: 6px;
            width: 100%;
            height: 100%;
            transition: 300ms;
            color: #ffffff;
        }

        .button-64:hover span {
            background: none;
        }

        @media (min-width: 768px) {
            .button-64 {
                // font-size: 10px;
                min-width: 196px;
            }
        }

        #pop {
            &.active {
                .soooner {
                    display: none;
                }
            }
        }
    }

    //   height: 500px;
    //   position: relative;
    //   overflow: hidden;
    //   .banner-container {
    //     //background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)), url("~@/assets/images/cata.jpg");
    //     height: 500px;
    //     width: 100%;

    //     img {
    //       width: 100%;
    //       height: 100%;
    //       object-position: top;
    //       object-fit: cover;
    //     }
    //     position: relative;
    //     //background-color: red;

    //     .banner-text-container {
    //       padding: 45px;
    //       position: absolute;
    //       bottom: 10%;
    //       width: 40%;
    //       background: rgba(0, 0, 0, 0.3);
    //       @include breakpoint(tablet) {
    //         width: 40%;
    //       }
    //       @include breakpoint(mobileonly) {
    //         width: 100%;
    //         padding: 11px;
    //         bottom: 0;
    //       }
    //       .banner-title {
    //         color: white;
    //         line-height: 1.4 !important;
    //         font-weight: 700;
    //         margin-bottom: 19px;
    //         margin-top: 0;
    //         @include breakpoint(mobileonly) {
    //           font-size: 20px;
    //         }
    //       }
    //       .banner-description {
    //         font-weight: 300;
    //         color: white;
    //         font-size: 19px;
    //         @include breakpoint(mobileonly) {
    //           font-size: 15px;
    //         }
    //       }
    //     }
    //   }
}
</style>
