<template>
  <section id="inscription">
    <div class="grid-container full">
      <div class="grid-x grid-margin-x">
        <div class="cell small-12 medium-6 large-5 show-for-large">
          <div class="bg">
            <img src="@/assets/images/concertppl.jpg" alt="" />
          </div>
        </div>
        <div class="cell small-12 medium-12 large-7">
          <!-- <div class="formcontainer">
            <div class="screen">
              <div class="formcontent">
                <router-link to="/">
                  Put here the logo with the correct size
                </router-link>
              </div>
              <div class="screen__background">
                <span
                  class="screen__background__shape screen__background__shape4"
                ></span>
                <span
                  class="screen__background__shape screen__background__shape3"
                ></span>
                <span
                  class="screen__background__shape screen__background__shape2"
                ></span>
                <span
                  class="screen__background__shape screen__background__shape1"
                ></span>
              </div>
            </div>
          </div> -->

          <div class="grid-x align-center">
            <!-- <div class="theform">
              <form action="" class="grid-x grid-margin-x grid-margin-y">
                <div class="grid-x grid-margin-x grid-margin-y">
                  <div class="cell small-12 medium-9 large-9">
                    <div class="form__group field">
                      <label for="nameorg" class="form__label"
                        >Nom de l'organisateur</label
                      >
                      <input
                        type="input"
                        class="form__field"
                        placeholder="Name"
                        name="nameorg"
                        id="name"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="
                    small-12
                    medium-6
                    cell
                    fix-search-input_wrapper
                    contact-form-inputs
                  "
                >
                  <input
                    placeholder="Prénom"
                    id="firstName"
                    class="contact_form_input"
                    required
                  />
                  <span class="form-error-field"></span>
                </div>

                <div class="grid-x grid-margin-x grid-margin-y">
                  <div class="cell small-12 medium-6 large-3">
                    <div class="form__group field">
                      <label for="name" class="form__label">Nom</label>
                      <input
                        type="input"
                        class="form__field"
                        placeholder="Name"
                        name="name"
                        id="name"
                        required
                      />
                    </div>
                  </div>
                  <div class="cell small-12 medium-6 large-3">
                    <div class="form__group field">
                      <label for="prename" class="form__label">Prénom</label>
                      <input
                        type="input"
                        class="form__field"
                        placeholder="Name"
                        name="prename"
                        id="name"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="grid-x grid-margin-x grid-margin-y">
                  <div class="cell small-12 medium-9 large-9">
                    <div class="form__group field">
                      <label for="name" class="form__label"
                        >Date de naissance (jj/mm/aa)</label
                      >
                      <input
                        type="input"
                        class="form__field"
                        placeholder="Name"
                        name="name"
                        id="name"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="grid-x grid-margin-x grid-margin-y">
                  <div class="cell small-12 medium-9 large-9">
                    <div class="form__group field">
                      <label for="name" class="form__label">E-mail</label>
                      <input
                        type="input"
                        class="form__field"
                        placeholder="Name"
                        name="name"
                        id="name"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="grid-x grid-margin-y">
                  <div class="cell small-12 medium-6 large-3">
                    <div class="form__group field">
                      <label for="name" class="form__label">Nom</label>
                      <input
                        type="input"
                        class="form__field"
                        placeholder="Name"
                        name="name"
                        id="name"
                        required
                      />
                    </div>
                  </div>
                  <div class="cell small-12 medium-6 large-3">
                    <div class="form__group field">
                      <label for="prename" class="form__label"
                        >Confirmation mot de passe</label
                      >
                      <input
                        type="input"
                        class="form__field"
                        placeholder="Name"
                        name="prename"
                        id="name"
                        required
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div> -->

            <div class="container">
              <div class="title">Nous contacter</div>
              <div class="content">
                <form @submit.prevent="submitForm">
                  <div class="user-details">
                    <div class="form_item">
                      <span class="details">Nom et Prénom</span>
                      <input
                        class="form_control"
                        placeholder="Nom et Prénom *"
                        type="name"
                        required
                        v-model="fullName"
                      />
                    </div>

                    <div class="form_item">
                      <span class="details">Numéro de téléphone</span>
                      <vue-tel-input v-model="phoneNumber"></vue-tel-input>
                    </div>
                    <div class="form_item">
                      <span class="details">Email</span>
                      <input

                        class="form_control"
                        placeholder="Adresse email"
                        type="email"
                        v-model="email"
                        required
                      />
                    </div>
                    <div class="form_item">
                      <span class="details">Sujet du message</span>
                      <input
                        class="form_control"
                        placeholder="Sujet du message"
                        type="text"
                        required
                        v-model="subject"
                      />
                    </div>
                    <div class="form_item">
                      <span class="details">Votre message</span>
                      <textarea
                        class="form_control"
                        style="min-height: 100px"
                        type="text"
                        placeholder="Votre message ici"
                        required
                        v-model="message"
                      ></textarea>
                    </div>
                  </div>
                  <div class="butt">
                    <input style="background: #a460f9 !important; background-image: none" type="submit" value="Envoyer le message" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import {httpClient} from "@/service/http";

export default {
  name: "ContactUsForm",
  props: {
    product: Object,
  },
    data() {
      return {
          fullName: '',
          phoneNumber: '',
          email: '',
          subject: '',
          message: '',
      }
    },
    methods:{
        async submitForm() {
            this.$swal.showLoading();
            try {
                const response = await httpClient.post(`/contactus`, {
                    fullName: this.fullName,
                    phoneNumber: this.phoneNumber,
                    email: this.email,
                    subject: this.subject,
                    message: this.subject,
                });

                this.fullName = '';
                this.phoneNumber = '';
                this.email = '';
                this.subject = '';
                this.message = '';

                this.alert2(response.data.data.notification).then();

            } catch (e) {
                console.log(e)
                this.alert2(e.response.data.message).then();
            }


        },
        async alert2(msg, title) {
            msg = String(msg)
            this.$swal({
                text: msg,
                title: title || "Informations",
                icon: 'info',
                dangerMode: true
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/partials/global";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

#inscription {
  font-family: "Poppins", sans-serif;
  $primary: #11998e;
  $secondary: #38ef7d;
  $white: #fff;
  $gray: #9b9b9b;
  // min-height: 80vh;
  margin-bottom: 40px;

  @include breakpoint(laptop) {
    margin: 40px;
    border-radius: 5px;
  }

  .bg {
    // background: url("../assets/images/login.jpg");
    // height: 100%;
    // padding-bottom: 0;
    // background-repeat: no-repeat;
    // background-size: contain;

    // position: fixed;
    // top: -50%;
    // left: -50%;
    width: 150%;
    height: 200%;
    border-radius: 5px;

    img {
      // position: absolute;
      // top: 0;
      // left: 0;
      // right: 0;
      // bottom: 0;
      margin: 0 auto;
      min-width: 50%;
      min-height: 50%;
      border-radius: 5px;
    }
  }

  .form_item {
    margin: 20px auto;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    span.details {
      display: block;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .form_control {
      display: block;
      width: 100%;
      -webkit-box-shadow: 0 0 5px 0 rgb(170 170 170 / 35%);
      box-shadow: 0 0 5px 0 rgb(170 170 170 / 35%);
      font-size: 12px;
      border-radius: 20px;
      border: 1px solid #ced4da;
      padding: 12px 30px;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      background-color: #fff;
      background-clip: padding-box;
      height: 45px;
    }
  }

  .container {
    max-width: 700px;
    width: 100%;
    background-color: #fff;
    padding: 25px 30px;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }
  .container .title {
    font-size: 25px;
    font-weight: bold;
    position: relative;
  }
  .container .title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    border-radius: 5px;
    background-image: linear-gradient(
      62deg,
      $primary-color 0%,
      $secondary-color 100%
    );
  }
  .content form .user-details {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    margin: 20px 0 12px 0;
  }
  form .user-details .input-box {
    margin-bottom: 15px;
    // width: calc(100% / 2 - 20px);
  }
  form .input-box span.details {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .user-details .input-box input {
    height: 45px;
    width: 100%;
    outline: none;
    font-size: 16px;
    border-radius: 5px;
    padding-left: 15px;
    border: 1px solid #ccc;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
  }
  .user-details .input-box input:focus,
  .user-details .input-box input:valid {
    border-color: #fbab7e;
  }
  form .gender-details .gender-title {
    font-size: 20px;
    font-weight: 500;
  }
  form .category {
    display: flex;
    width: 80%;
    margin: 14px 0;
    justify-content: space-between;
  }
  form .category label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  form .category label .dot {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    margin-right: 10px;
    background: #d9d9d9;
    border: 5px solid transparent;
    transition: all 0.3s ease;
  }
  #dot-1:checked ~ .category label .one,
  #dot-2:checked ~ .category label .two,
  #dot-3:checked ~ .category label .three {
    background: #9b59b6;
    border-color: #d9d9d9;
  }
  form input[type="radio"] {
    display: none;
  }
  form .butt {
    height: 45px;
    margin: 35px 0;
    transition: all 1s ease;
  }
  form .butt input {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s ease;
    // background-color: #fbab7e;
    background-image: linear-gradient(
      62deg,
      $primary-color 0%,
      $secondary-color 100%
    );
  }
  form .butt input:hover {
    /* transform: scale(0.99); */
    // background-color: #fbab7e;
    background-image: linear-gradient(
      250deg,
      $primary-color 0%,
      $secondary-color 100%
    );
  }
  @media (max-width: 584px) {
    .container {
      max-width: 100%;
    }
    form .user-details .input-box {
      margin-bottom: 15px;
      width: 100%;
    }
    form .category {
      width: 100%;
    }
    // .content form .user-details {
    //   //max-height: 300px;
    //   //overflow-y: scroll;
    // }
    .user-details::-webkit-scrollbar {
      width: 5px;
    }
  }
  @media (max-width: 459px) {
    .container .content .category {
      flex-direction: column;
    }
  }

  // .theform {
  //   padding: 40px;
  //   background-color: #fff;
  //   border-radius: 6px;
  //   box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
  //     0 0 0 1px rgb(10 10 10 / 2%);

  //   .form__group {
  //     position: relative;
  //     padding: 15px 0 0;
  //     margin-top: 10px;
  //     width: 70%;
  //     margin-bottom: 20px;
  //   }

  //   .form__field {
  //     font-family: inherit;
  //     width: 100%;
  //     border: 0;
  //     border-bottom: 2px solid $gray;
  //     border-radius: 6px;
  //     outline: 0;
  //     font-size: 1.1em;
  //     color: #000000;
  //     padding: 7px 0;
  //     background: transparent;
  //     transition: border-color 0.2s;

  //     &::placeholder {
  //       color: transparent;
  //     }

  //     &:placeholder-shown ~ .form__label {
  //       font-size: 1rem;
  //       cursor: text;
  //       top: 20px;
  //     }
  //   }

  //   .form__label {
  //     position: absolute;
  //     top: 0px;
  //     display: block;
  //     transition: 0.2s;
  //     font-size: 10px;
  //     color: $primary-color;
  //     font-weight: bold;
  //   }

  //   .form__field:focus {
  //     ~ .form__label {
  //       position: absolute;
  //       top: 0;
  //       display: block;
  //       transition: 0.2s;
  //       font-size: 1rem;
  //       color: $primary-color;
  //       font-weight: 700;
  //     }
  //     padding-bottom: 6px;
  //     font-weight: 700;
  //     border-width: 3px;
  //     border-image: linear-gradient(to right, $primary-color, $secondary-color);
  //     border-image-slice: 1;
  //   }
  //   /* reset input */
  //   .form__field {
  //     &:required,
  //     &:invalid {
  //       box-shadow: none;
  //     }
  //   }
  // }

  // .formcontainer {
  //   // padding: 2.5em;
  //   min-height: 100vh;

  //   .screen {
  //     background: #f0eef7;
  //     //   background: linear-gradient(90deg, #9c49e5, #7c78b8);
  //     position: relative;
  //     height: 600px;
  //     padding: 2.5em;
  //     /* width: 360px; */
  //     //   box-shadow: 0px 0px 24px #5c5696;

  //     .screen__background {
  //       position: absolute;
  //       top: 0;
  //       left: 0;
  //       right: 0;
  //       bottom: 0;
  //       z-index: 0;
  //       -webkit-clip-path: inset(0 0 0 0);
  //       clip-path: inset(0 0 0 0);

  //       .screen__background__shape {
  //         transform: rotate(45deg);
  //         position: absolute;
  //         opacity: 0.5;
  //       }
  //       .screen__background__shape4 {
  //         height: 400px;
  //         width: 200px;
  //         background: #7e7bb9;
  //         top: 420px;
  //         right: 50px;
  //         border-radius: 60px;
  //       }
  //       .screen__background__shape3 {
  //         height: 240px;
  //         width: 190px;
  //         background: linear-gradient(270deg, #5d54a4, #6a679e);
  //         top: -24px;
  //         right: 0px;
  //         border-radius: 32px;
  //       }

  //       .screen__background__shape2 {
  //         height: 220px;
  //         width: 220px;
  //         background: #6c63ac;
  //         top: -172px;
  //         right: 0;
  //         border-radius: 32px;
  //       }

  //       .screen__background__shape1 {
  //         height: 320px;
  //         width: 320px;
  //         background: #fff;
  //         top: 150px;
  //         left: -180px;
  //         border-radius: 0 72px 0 0;
  //       }
  //     }
  //   }
  // }
}
</style>
