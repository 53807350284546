<template>
    <section id="pricing">
        <div class="grid-container">
            <div class="grid-x grid-margin-x grid-margin-y align-center">
                <div class="cell small-12 medium-12 large-5">
                    <div class="pricingContainer">
                        <div class="pricingContent">
                            <span class="eventType">Evènement payant</span>
                            <div class="eventPrice">
                                <h1>
                                    <span class="price">6.5%</span>
                                    <span class="perper">/par ticket</span>
                                </h1>
                            </div>
                            <div class="eventBody">
                                <ul class="bodyOptions">
                                    <li>Tickets avec QR code</li>
                                    <li>Statistiques des ventes</li>
                                    <li>Statistiques des tickets</li>
                                    <li>Gestion des points de ventes</li>
                                    <li>
                                        Génération de plusieurs types de tickets
                                    </li>
                                </ul>

                                <a
                                    class="bodyBtn mb-3"
                                    :href="backOfficeUrl + '/login'"
                                    >S'inscrire
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cell small-12 medium-12 large-5">
                    <div class="pricingContainer">
                        <div class="pricingContent">
                            <span class="eventType">Evènement gratuit</span>
                            <div class="eventPrice">
                                <h1>
                                    <span class="price">500 Fcfa</span>
                                    <span class="perper">/par personne</span>
                                </h1>
                            </div>
                            <div class="eventBody">
                                <ul class="bodyOptions">
                                    <li>Tickets avec QR code</li>
                                    <li>Statistiques des ventes</li>
                                    <li>Statistiques des tickets</li>
                                    <li>Gestion des points de ventes</li>
                                    <li>
                                        Génération de plusieurs types de tickets
                                    </li>
                                </ul>

                                <a
                                    class="bodyBtn mb-3"
                                    :href="backOfficeUrl + '/login'"
                                    >S'inscrire
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { backOfficeUrl } from "@/service/const";
export default {
    data() {
        return {
            backOfficeUrl: backOfficeUrl
        };
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/partials/global";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

#pricing {
    .pricingContainer {
        border-radius: 0.25rem;
        .pricingContent {
            position: relative;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            border: 3px solid $primary-color;
            border-radius: 0.5rem;
            .eventType {
                text-align: center;
                font-size: 1rem;
                margin: 0 auto;
                width: 50%;
                background-color: $primary-color;
                color: white;
                box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
                border-bottom-right-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
                padding: 0.25rem 1.5rem;
            }

            .eventPrice {
                padding: 0.75rem 1.25rem;
                h1 {
                    font-weight: 400;
                    text-align: center;
                    font-size: 2.5rem;
                    margin-top: 20px;

                    .perper {
                        font-size: 1rem;
                        margin-left: 0.5rem;
                    }
                }
            }
            .eventBody {
                flex: 1 1 auto;
                padding: 0rem 1.25rem 1.25rem 1.25rem;
                text-align: center;

                .bodyOptions {
                    padding-left: 0 !important;
                    list-style: none !important;
                    margin: 0 !important;
                    margin-bottom: 1.5rem !important;

                    li {
                        padding: 0.5rem 0;
                        color: #6c757d;
                        text-align: center;
                    }
                }

                .bodyBtn {
                    display: inline-block;
                    font-weight: 500;
                    text-align: center;
                    white-space: nowrap;
                    vertical-align: middle;
                    color: #1a1b1c;
                    border: 1px solid transparent;
                    padding: 0.375rem 0.75rem;
                    font-size: 1rem;
                    line-height: 1.5;
                    border-radius: 0.25rem;
                    border-color: #6c757d;
                    transition: color 0.15s ease-in-out,
                        background-color 0.15s ease-in-out,
                        border-color 0.15s ease-in-out,
                        box-shadow 0.15s ease-in-out;
                }
            }
        }
    }
}
</style>
