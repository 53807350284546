<template>
  <section id="signin">
    <!-- <div class="innerpage-decor">
      <div class="innerpage-circle1">
        <img src="@/assets/images/Testimonial2.png" alt="" />
      </div>
      <div class="innerpage-circle2">
        <img src="@/assets/images/Testimonial1.png" alt="" />
      </div>
    </div> -->
    <div class="content">
      <div class="theform">
        <h2><span>Se connecter</span></h2>
        <p>
          Bienvenue sur Paytick, Veuillez vous connecter avec les informations
          de votre compte personnel.
        </p>
        <form method="POST" action="/login">
          <input type="hidden" name="_token" :value="csrf" />
          <div class="theme_form">
            <div class="form_item">
              <input
                class="form_control"
                placeholder="Adresse email"
                type="email"
                required
              />
            </div>
            <div class="form_item">
              <input
                class="form_control"
                placeholder="Mot de passe"
                type="password"
                required
              />
            </div>
            <div class="form_button">
              <button class="btn_cutom" type="submit">
                <svg>
                  <rect x="0" y="0" fill="none" width="100%" height="100%" />
                </svg>
                se connecter
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
window.location.href = "/login";
</script>
<script>
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Connexion",
  data: () => ({
    csrf: document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content"),
  }),
  //   components: {
  //     HelloWorld
  //   },
};
</script>


<style lang="scss" scoped>
@import "../assets/sass/partials/global";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

#signin {
  padding-bottom: 100px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  //   background: #fafafa;
  //   background-image: url(../images/auth-bg.jpg);
  background-repeat: no-repeat;
  position: relative;

  .innerpage-decor {
    .innerpage-circle1 {
      position: absolute;
      left: 247px;
      bottom: 250px;
    }
    .innerpage-circle2 {
      position: absolute;
      left: 50px;
      top: auto;
      bottom: 20px;
    }
  }

  .content {
    h2 {
      line-height: 28px;
      font-size: 30px;
      font-weight: 300;
      font-family: "Poppins", sans-serif;
      margin-bottom: 20px;
      color: #000000;
      text-transform: uppercase;
      letter-spacing: 4px;
      text-align: center;
    }
    p {
      width: 70%;
      margin: 0 auto 30px;
      font-family: "Poppins", sans-serif;
      line-height: 1.8em;
      font-size: 15px;
      color: #000000;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      text-align: center;
    }

    .theform {
      border: 0;
      width: 500px;
      border-top: 3px solid $primary-color;
      padding: 30px 50px 50px;
      width: 500px;
      margin: 0 auto;
      border-radius: 0;
      color: #ffffff;
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: rgba($color: #fafafa, $alpha: 1);
      background-clip: border-box;

      .theme_form {
        .form_item {
          margin: 25px auto;
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;

          .form_control {
            display: block;
            width: 100%;
            -webkit-box-shadow: 0 0 5px 0 rgb(170 170 170 / 35%);
            box-shadow: 0 0 5px 0 rgb(170 170 170 / 35%);
            font-size: 12px;
            border-radius: 20px;
            border: 1px solid #ced4da;
            padding: 12px 30px;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            background-clip: padding-box;
          }
        }
        .form_button {
          text-align: center;

          .btn_cutom {
            background-color: #ffffff;
            color: $primary-color;
            font-size: 14px;
            border-radius: 3px;
            text-transform: uppercase;
            padding: 10px 25px;
            cursor: pointer;
            border: none;
            background-size: 300% 100%;
            -webkit-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
            position: relative;
            // background: darken($primary-color, 1.5%);
            // font-weight: 100;

            svg {
              height: 35px;
              left: 0;
              position: absolute;
              top: 0;
              width: 100%;
            }

            rect {
              fill: none;
              stroke: $primary-color;
              stroke-width: 2;
              stroke-dasharray: 422, 0;
              transition: all 0.35s linear;
            }

            &:hover {
              background: rgba($primary-color, 0);
              font-weight: 900;
              letter-spacing: 1px;

              rect {
                stroke-width: 5;
                stroke-dasharray: 15, 310;
                stroke-dashoffset: 48;
                transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
              }
            }
          }
        }
      }
    }
  }
}
</style>
