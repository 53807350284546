<template>
  <!-- <div class="ProductCard">
    <div class="Product-Composent">
      <div class="grid-y grid-margin-y">
        <div class="cell small-6 medium-6 large-6">
          <div class="Product-image">
            <a href="" class="separation">
              <img :src="product.imageUrl" alt="" />
            </a>
          </div>
        </div>
        <div class="cell small-6 medium-6 large-6">
          <div class="Product-info">
            <div class="grid-y grid-margin-y">
              <a href="">
                <div class="cell small-6 medium-6 large-6">
                  <div class="Product-name">
                    <h6>{{ product.name }}</h6>
                  </div>
                </div>
                <div class="cell small-6 medium-6 large-6">
                  <div class="Product-price">
                    <p class="Product-price">{{ product.price }}</p>
                  </div>
                </div>
              </a>

              <button @click="$emit('btn-clicked')" class="button">
                Ajouter au panier
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- <div class="result_event_container">
    <a href="#">
      <div class="result_event">
        <img
          src="https://www.billetweb.fr/files/event/200_max/515680.jpg"
          alt=""
        />
        <div class="result_event_text">
          <div class="description">
            <div class="title">Concert Un Sol Hit</div>
            <div class="date"><span>26/01/2022 à 19h00</span></div>
            <div class="place">Varces-Allières-et-Risset</div>
          </div>
          <button @click="$emit('btn-clicked')" class="button">Réserver</button>
        </div>
      </div>
    </a>
  </div> -->

    <router-link
        class="buttonn"
        :to="{
              name: 'achatticket',

              params: {
               _product: product,
                eventslug: product.slug,
              },
            }">
        <div class="thenewcard">
            <img :src="product.imageUrl" alt="" />
            <div class="eventbutton">Acheter</div>
        </div>
    </router-link>
</template>

<script>
export default {
  name: "productCard",
  props: {
    product: Object,
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/partials/global";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.thenewcard {
    position: relative;
    margin: 0 10px;

    .eventbutton {
        position: absolute;
        bottom: 0;
        background: #a460f9;
        /* margin: 0 auto; */
        width: 100%;
        text-align: center;
        padding: 5px;
        color: white;


        a {
            margin: 0 auto;
            padding: 20px;
        }
    }
}
// .ProductCard {
//   @include box0;
//   display: flex;
//   width: auto;
//   height: auto;
//   margin: 10px;
//   overflow: hidden;
//   font-family: "Poppins", sans-serif;

//   .Product-Composent {
//     height: auto;

//     /* .Product-image{
//                 a img{
//                    width : 223.74px;
//                    height : 170px;
//                 }
//             } */

//     .Product-info {
//       @include box16;
//       padding: 0 10px 10px 10px;
//       background: #f0eef7;

//       .button {
//         border: 2px solid #9c49e5;
//         letter-spacing: 2px;
//         font-size: 12px;
//         background: #9c49e5;
//         color: #fff;
//         padding: 0;

//         &:hover {
//           transition: all 0.3s ease-in-out;
//           background-color: transparent;
//           border: 2px solid #9c49e5;
//           color: #9c49e5;
//         }
//       }

//       // a {
//       //   font-weight: 600;
//       //   color: #384657;

//       //   .Product-price {
//       //     font-size: 15px;
//       //     color: #797979;
//       //   }
//       // }
//     }
//   }

//   &:hover {
//     img {
//       overflow: hidden;
//       transition: all 0.3s ease-in-out;
//       transform: scale(1.1);
//     }
//   }
// }

// .result_event_container {
//   margin: 20px;
//   width: 200px;
//   height: 270px;
//   display: inline-block;
//   vertical-align: top;

//   .result_event {
//     height: 270px;
//     position: relative;
//     overflow: hidden;
//     background-color: #000;
//     border-radius: 3px;
//     box-shadow: 3px 2px 4px #90909061;

//     .result_event_text {
//       text-align: center;
//       position: absolute;
//       left: 0;
//       right: 0;
//       top: 210px;
//       padding: 5%;
//       color: #fff;
//       z-index: 1;
//       transition: all 0.3s;

//       .description {
//         min-height: 70px;

//         .title {
//           color: #9c49e5;
//           font-weight: 600;
//           font-size: 16px;
//           margin-bottom: 0;
//         }

//         .date {
//           font-size: 12px;
//           font-weight: 600;
//           color: #000;
//         }

//         .place {
//           font-size: 11px;
//           color: #ccc;
//           line-height: 20px;
//           white-space: nowrap;
//           overflow: hidden;
//           text-overflow: ellipsis;
//         }
//       }

//       .button {
//         border: 2px solid #9c49e5;
//         letter-spacing: 2px;
//         font-size: 12px;
//         background: #9c49e5;

//         &:hover {
//           transition: all 0.3s ease-in-out;
//           background-color: transparent;
//           border: 2px solid #9c49e5;
//           color: #9c49e5;
//         }
//       }

//       &:after {
//         content: "";
//         background-color: #f0eef7;
//         opacity: 0.8;
//         left: -20%;
//         right: -30%;
//         top: -10%;
//         bottom: -50%;
//         position: absolute;
//         z-index: -1;
//         -webkit-backface-visibility: hidden;
//         backface-visibility: hidden;
//         -webkit-transform: rotate(-0deg);
//         transform: rotate(-0deg);
//       }
//     }
//   }

//   &:hover {
//     .result_event_text {
//       top: 110px;
//     }
//   }
// }

.texxt {
  .boxo {
    display: flex;
    // width: 1040px;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .card {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    // box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
    border: 1px;
  }

  .card-header img {
    //this zoom the image
    // width: 100%;
    // height: 200px;
      object-fit: fill;
      height: 115px;
      width: 100% !important;
  }

  .card-body {
    //  display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: flex-start;
    text-align: center;
    padding: 20px;
    min-height: 200px;

    background: $secondary-color;

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        font-size: 13px;
        margin-bottom: 10px;
        //display: -webkit-box;
      }
    }
  }

  .tag {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;

    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    margin: 5px auto;
  }

  .tag-teal {
    background-color: #9c49e5;
  }

  .tag-purple {
    background-color: #5e76bf;
  }

  .tag-pink {
    background-color: #cd5b9f;
  }

  .buttonn {
    border: 2px solid #9c49e5;
    letter-spacing: 2px;
    font-size: 12px;
    background: #9c49e5;
    padding: 0.5em 1em;
    color: #fff;

    &:hover {
      transition: all 0.3s ease-in-out;
      background-color: transparent;
      border: 2px solid #9c49e5;
      color: #9c49e5;
    }
  }

  // .user {
  //   display: flex;
  //   margin-top: auto;
  // }

  // .user img {
  //   border-radius: 50%;
  //   width: 40px;
  //   height: 40px;
  //   margin-right: 10px;
  // }

  // .user-info h5 {
  //   margin: 0;
  // }

  // .user-info small {
  //   color: #545d7a;
  // }
}
</style>
