<template>
  <div id="app">
    <AppHeader />

    <main>
      <keep-alive exclude="Achatticket" >
         <router-view />
      </keep-alive>

    </main>

    <div v-if="!['achatticket', 'downloadTicket'].includes(this.$route.name)">
      <AppFooter />
    </div>
  </div>
</template>

<script>
//import M from 'materialize-css';
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";
//import Appshop from "./views/Appshop.vue";
//import Accueil from "./views/Accueil.vue";

export default {
  mounted() {
    //  M.AutoInit();
    // console.log(this.$route.name);
  },
  components: {
    AppHeader,
    AppFooter,
    //Appshop,
    //Accueil,
  },
  head: {
    link: [
      {
        rel: "stylesheet",
        href: "https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css",
      },

      // ...
    ],
  },
};
</script>

<style lang="scss">
@import "assets/sass/app.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #181818;
  color: #2c3e50;
}

#nav {
  padding: 20px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: rgba(66, 185, 131, 0.973);
    }
  }
}
</style>
