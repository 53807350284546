<template>
    <div class="SHOP">
        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="cell small-12 medium-12 large-3">
                    <div class="dashboard">
                        <h5 class="underlined-section-title-left">Evènements</h5>
                        <ul class="under">
                            <li style="font-weight: bold">
                                <a @click="getProductByCategory('')" href="#"
                                >Toutes les catégories</a
                                >
                            </li>

                            <div style="text-align: center;" v-if="categories.length === 0">
                                <li>
                                    <!-- <a style="font-size: 13px" href="#">Chargement en cours</a> -->
                                    <img width="50px" src="@/assets/images/spinner.svg" alt=""/>
                                </li>
                            </div>

                            <li v-for="category in categories" :key="category.slug">
                                <a @click="getProductByCategory(category.slug)" href="#">{{
                                        category.name
                                    }}</a>
                            </li>
                        </ul>
                    </div>

                    <!-- <div v-if="shopCart.length > 0" class="shopping-cart">
                      <h5 class="underlined-section-title-left">Shopping Cart</h5>
                      <ul class="under">
                        <li v-for="cart in shopCart" :key="cart.id" class="mini-cart_item">
                          <div class="grid-x grid-margin-x">
                            <div class="cell small-4 medium-4 large-4">
                              <a href="#"><img :src="cart.imageUrl" alt=""></a>
                            </div>
                            <div class="cell small-4 medium-4  large-8">
                              <div class="info">
                                <span class="name"><a href="">{{cart.name}}</a></span>
                                <span class="name"> {{cart.price}}</span>
                              </div>
                            </div>

                          </div>
                        </li>
                      </ul>
                    </div> -->
                </div>

                <div class="cell small-12 medium-12 large-9">
                    <div
                        :class="
              'card-container ' +
              ((this.noEventCat() || this.noEvent()) ? 'loading' : '')
            "
                    >
                        <div class="grid-container full">
                            <div
                                class="cell align-center"
                                v-if="this.isFetching()"
                            >
                                <div style="text-align: center" class="notfound">
                                    <img src="@/assets/images/spinner.svg" alt=""/>
                                </div>
                            </div>
                            <div v-if="this.noEventCat()">
                                <div class="notfound">
                                    <h1 style="margin-top: 0px">Pas d'évènement disponible pour cette catégorie</h1>
                                </div>
                            </div>
                            <div v-if="this.noEvent()">
                                <div class="notfound">
                                    <h1 style="margin-top: 0px">Pas d'évènement disponible</h1>
                                </div>
                            </div>
                            <div v-if="filteredProducts.length > 0" class="grid-x grid-margin-x grid-margin-y">
                                <div
                                    class="cell small-6 medium-6 large-4"
                                    v-for="product in filteredProducts"
                                    :key="product.id"
                                >
                                    <productCard
                                        :product="product"
                                        @btn-clicked="addToCart(product)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div style="text-align: center;" class=""
                             v-if="(hasMore && filteredProducts.length === products.length && products.length > 0)">
                            <button v-if="!loadingMore" @click="loadMore()" class="buttonn light button" role="button"
                                    style="height: 45px;border: 2px solid #9c49e5;background: #9c49e5;;margin: 18px 18px 27px;">
                                Charger Plus
                            </button>

                            <img v-if="loadingMore" width="50px" src="@/assets/images/spinner.svg" alt=""/>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import productCard from "../components/AppProductCard.vue";
import {sleep} from "@/service/utils";
import {httpRetryInterval} from "@/service/const";
import {httpClient} from "@/service/http";

export default {
    name: "Shop",
    components: {
        productCard,
    },

    data() {
        return {
            categories: [],
            products: [],
            filteredProducts: [],
            currentCatSlug: "",
            currentPage: 1,
            hasMore: true,
            loadingMore: false,
        };
    },
    mounted() {
        this.filteredProducts = this.products;
        this.fetchCategories();
        this.fetchProducts(this.currentPage);
    },
    computed: {
        shopCart() {
            return this.$store.getters.shopCart;
        },
    },
    methods: {
        noEvent() {
            return (!this.currentCatSlug && this.products.length === 0 && !this.hasMore);
        },
        noEventCat() {
            return (this.filteredProducts.length === 0 && this.currentCatSlug);
        },
        isFetching() {
            return !this.noEvent() && !this.noEventCat() && this.products.length === 0;
        },
        async loadMore() {
            if (!this.loadingMore) {
                this.loadingMore = true;
                await this.fetchProducts(this.currentPage);
                this.loadingMore = false;
            }
        },
        async fetchProducts(page) {
            return new Promise((resolve) => {
                (async () => {
                    try {
                        const response = await httpClient.get(`/events?page=${page}`);
                        const prods = response.data;
                        this.products = this.products.concat(prods);
                        this.filteredProducts = this.products;
                        this.currentPage++;

                        if (prods.length === 0) {
                            this.hasMore = false;
                        }

                        resolve(prods);
                    } catch (e) {
                        console.log(e);
                        await sleep(httpRetryInterval);
                        this.fetchProducts(page).then();
                    }
                })();
            });
        },

        async fetchCategories() {
            return new Promise((resolve) => {
                (async () => {
                    try {
                        const response = await httpClient.get('/categories');
                        const cats = response.data;
                        console.log(cats)
                        this.categories = cats;
                        resolve(cats);
                    } catch (e) {
                        console.log(e);
                        await sleep(httpRetryInterval);
                        this.fetchCategories().then();
                    }
                })();
            });
        },
        getProductByCategory(categorySlug) {
            console.log(categorySlug);
            this.currentCatSlug = categorySlug;
            if (categorySlug === "") {
                this.filteredProducts = this.products;
            } else {
                this.filteredProducts = this.products.filter(
                    (product) => product.category.slug === categorySlug
                );
                console.log(this.products);
            }
        },

        addToCart(product) {
            this.$store.dispatch("actionAddtoCart", product);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/partials/global";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.SHOP {
    margin-top: 20px;
    margin-bottom: 50px;

    .notfound {
        padding: 20px;

        h1 {
            font-family: "Open Sans", -apple-system, "Segoe UI", sans-serif;
            font-size: 30px;
            font-weight: bold;
            color: #ffffff;
            box-sizing: border-box;
            text-align: center;
        }


    }

    .dashboard,
    .shopping-cart {
        height: inherit;
        background: white;
        padding: 15px;
        margin-bottom: 15px;

        @include box0;

        .underlined-section-title-left {
            position: relative;
            margin-bottom: 33px;

            &::after {
                content: "";
                position: absolute;
                display: inline-block;
                bottom: -13px;
                width: 58px;
                height: 3px;
                left: 0;
                background-color: #9c49e5;
            }
        }

        .under {
            margin: 0px;
            padding: 10px;
            border: 0.9px solid #9c49e5;
            list-style: none;

            li {
                padding: 5px;

                a {
                    color: #9c49e5;
                }

                .info {
                    display: flex;
                    flex-direction: column;

                    .name {
                        a {
                            font-size: 75%;
                        }
                    }
                }
            }
        }
    }

    .card-container {
        @include box0;
        // margin-top: 14px;
        min-height: 700px;
        box-shadow: 0px 0px 30px 10px #000000;
        // padding: 0 0 0 20px;
    }

    .card-container.loading {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
