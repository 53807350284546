import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    shopCart: []
  },
  getters:{
    shopCart:(state)=>{
      return state.shopCart;

    }

  },
  mutations: {
    ADD_TO_CART(state,produit){
      state.shopCart.push(produit);
    }
  },
  actions: {
    actionAddtoCart({commit},item){
      commit('ADD_TO_CART',item)
    }
  },
  modules: { 
  }
})
