<template>
    <section id="purchase">
        <div class="grid-container">
            <div class="grid-x" v-if="this.product">
                <div class="cell small-12 medium-12 large-6">
                    <div class="leftcontent">
                        <img :src="product.imageUrl" alt=""/>
                    </div>
                </div>
                <div class="cell small-12 medium-12 large-6">
                    <div class="content">
                        <div class="theform">
                            <h2 v-if="false"><span>Télécharger</span></h2>
                            <a style="background: #a460f9;color: #fff;border: 1px solid #a460f9;height: 42px" target="_blank"  :href="`https://webadmin.paytech.sn/pdf/${billId}`" class="button-64 button btn-success" role="button"><span class="text">Télécharger la facture</span></a>

                            <!--<p>{{ product.name }}</p>
                            <div style="display: flex" class="info">
                                <p>
                                    <img src="@/assets/images/location.svg" alt=""/>

                                    {{ product.location }}
                                </p>
                                <p>
                                    <img src="@/assets/images/date.svg" alt=""/>
                                    {{ product.date }}
                                </p>
                            </div>-->
                            <div id="downloadArea" style="height: 700px">

                                <slick v-if="buyInfo &&  buyInfo.status" ref="slick" :options="slickOptions">
                                    <div v-for="(ticket, index) in this.buyInfo.tickets" :key="ticket.id">
                                        <figure style="margin: 0;margin-bottom: 10px"  class="orbit-figure">
                                            <img :id="'targetImage' + index"  class="orbit-image" style="display: inline-block;height:680px;text-align: center;line-height: 400px;"

                                                  :src="`${apiUrl}/qrCodeTicket/${ticket.encryptReference}`"
                                                  alt="">

                                        </figure>
                                        <div v-if="buyInfo.achatTicket.status === 'completed'" style="text-align: center">
                                            <button type="button" @click="downloadTicket('targetImage' + index,  `Ticket${index + 1}`)"  style="width: 100%;background: #a460f9;color: #fff;border: 1px solid #a460f9;height: 42px"   href="#" class="button-64 button btn-success" role="button"><span class="text">Télécharger le ticket № {{ index + 1}}</span></button>
                                        </div>

                                        <div v-if="buyInfo.achatTicket.status !== 'completed'" style="text-align: center">
                                            <button type="button"  style="opacity: 0.5;width: 100%;background: #a460f9;color: #fff;border: 1px solid #a460f9;height: 42px"   class="button-64 button btn-success" role="button"><span class="text">Generation du ticket en cours</span></button>
                                        </div>
                                    </div>

                                </slick>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import {httpClient} from "@/service/http";
import {sleep} from "@/service/utils";
import {apiUrl, httpRetryInterval} from "@/service/const";
import Slick from "vue-slick";

export default {
    name: "DownloadTicket",
    props: ["_product", "eventslug"],
    components: { Slick },
    created () {
       // document.title = `PayTick`;
    },
    data() {
        return {
            product: null,
            buyInfo: null,
            billId:'',
            apiUrl,
            slickOptions: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                horizontal: true,
                dots: false,
                //centerMode: true,
                autoplay: false,
                arrows: false,
                // centerPadding: "60px",
                // adaptiveHeight: true,
                mobileFirst: true,
                pauseOnFocus: false,
                pauseOnHover: false,

                // Any other options that can be got from plugin documentation
            },
        };
    },

    async mounted() {
        this.billId = this.$route.query.billId;
        this.fetchBuyInfo(this.$route.query.reference).then();
        if (!this._product) {
          await  this.fetchProductBySlug(this.eventslug);
        } else {
            this.product = this._product;
        }

        document.title = `Téléchargement ticket ${this.product.name}`;

        console.log(this.$route.query);
        console.log(this.product, this.eventslug);
    },
    methods: {
        reInit() {

            let currIndex = this.$refs.slick.currentSlide()
            this.$refs.slick.destroy()
            this.$nextTick(() => {
                this.$refs.slick.create()
                this.$refs.slick.goTo(currIndex, true)
            })
        },
        async download(_img, name) {

            var img = document.createElement('img');
            img.onload =  () => {
                try {
                    document.body.appendChild(img);

                    var canvas = document.createElement("canvas");

                    var w = 667 ;
                    var h = 1260;

                    canvas.width = w;
                    canvas.height = h;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, w, h);

                    console.log(ctx)

                    const imgURL = canvas.toDataURL("image/png");


                    const dlLink = document.createElement('a');
                    dlLink.download = encodeURIComponent(name);
                    dlLink.href = imgURL;
                    dlLink.dataset.downloadurl = ["image/png", dlLink.download, dlLink.href].join(':');

                    document.body.appendChild(dlLink);
                    dlLink.click();
                    document.body.removeChild(dlLink);
                    console.log(img, '2')
                   img.remove()
                } catch (e) {
                    console.log(e)
                    this.alert2(e.message)
                }
            };

            img.setAttribute('crossOrigin', 'anonymous');
            img.src = _img.getAttribute('src');

        },
        async downloadTicket(targetImageId, fileName) {
            this.download(document.getElementById(targetImageId), fileName).then();
        },
        async fetchProductBySlug(productSlug) {
            return new Promise((resolve) => {

                (async () => {
                    try {
                        const response = await httpClient.get(`/event-slug/${this.eventslug}`);
                        console.log(response)
                        const prod = response.data;
                        this.product = prod;
                        resolve(prod);
                        if (!prod.id) {
                            this.$router.push({ name: "PageNotFound" }).then();
                        }
                    } catch (e) {
                        await sleep(httpRetryInterval);
                        this.fetchProductBySlug(productSlug).then();
                    }


                })();

            });
        },
        async fetchBuyInfo(reference) {
            return new Promise((resolve) => {

                (async () => {
                    try {
                        const response = await httpClient.post(`/buyInfo`, {
                            reference: reference
                        });
                        const buyInfo =  response.data;

                        resolve();

                        if(!buyInfo.status){
                            await this.alert2(buyInfo.msg, 'Erreur').then();
                            this.$router.replace({ name: "PageNotFound" }).then();

                        }
                        else{
                            if(buyInfo.achatTicket.status === 'completed'){
                                //console.log(this.alert2('completed'));
                                this.buyInfo = buyInfo;
                                this.reInit();
                            }
                            else{
                                this.buyInfo = buyInfo;
                                await sleep(2000);
                                this.fetchBuyInfo(reference).then();
                            }
                        }


                    } catch (e) {
                        await sleep(httpRetryInterval);
                        this.fetchBuyInfo(reference).then();
                    }


                })();

            });
        },
        async submitForm() {
            const PayTech = window['PayTech'];
            (new PayTech({
                //_token:	"3gZfrwRBqTMNeCxFxQSdIQoxmhh9VEhCVfI9DhZa",
                type: this.type,
                nombre: this.nombre,
                nom: this.nom,
                email: this.email || '',
                telephone:	this.telephone.replace(/ /g,''),
                adresse:	this.adresse || ''
            })).withOption({
                requestTokenUrl: apiUrl + '/buyTicket',
                method: 'post',
                headers: {
                    "Accept": "text/html"
                },
                prensentationMode: PayTech.OPEN_IN_POPUP,
                willGetToken: () => {
                    console.log('will get token: *******');
                },
                didGetToken: (token, redirectUrl) => {
                    console.log('did Get Token: *******');
                    console.log(token, redirectUrl);
                },
                didReceiveError: (error) => {
                    this.alert2(JSON.stringify(error))
                    //  $('#modalalerte').modal('show');
                    console.log("didReceiveError, erreur ", error);
                },
                didReceiveNonSuccessResponse: (jsonResponse) => {
                    this.alert2((jsonResponse.error || [])[0] || jsonResponse.errors[0])
                    //  console.log('didReceiveNonSuccessResponse: *******', jsonResponse);
                }
            }).send();
        },
        async alert2(msg, title) {
            msg = String(msg)
            await this.$swal({
                text: msg,
                title: title || "Informations",
                icon: 'info',
                dangerMode: true
            });
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/partials/global";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

#purchase {
    padding-bottom: 50px;
    padding-top: 50px;
    min-height: 100vh;
    //   display: flex;
    align-items: center;
    justify-content: center;
    //   background: #fafafa;
    //   background-image: url(../images/auth-bg.jpg);
    background-repeat: no-repeat;
    position: relative;

    .innerpage-decor {
        .innerpage-circle1 {
            position: absolute;
            left: 247px;
            bottom: 250px;
        }

        .innerpage-circle2 {
            position: absolute;
            left: 50px;
            top: auto;
            bottom: 20px;
        }
    }

    .leftcontent {
        height: 100%;

        img {
            height: 100%;

            @include breakpoint(mobileonly) {
                height: auto;
            }
            object-fit: fill;
        }
    }

    .content {
        h2 {
            line-height: 28px;
            font-size: 30px;
            font-weight: 300;
            font-family: "Poppins", sans-serif;
            margin-bottom: 20px;
            color: #000000;
            text-transform: uppercase;
            letter-spacing: 4px;
            text-align: center;
        }

        p {
            width: 70%;
            margin: 0 auto 30px;
            font-family: "Poppins", sans-serif;
            line-height: 1.8em;
            font-size: 15px;
            color: #000000;
            letter-spacing: 0.03em;
            text-transform: capitalize;
            text-align: center;
        }

        .theform {
            border: 0;
            border-top: 3px solid $primary-color;
            padding: 30px 50px 50px;
            //   margin: 0 auto;
            border-radius: 0;
            color: #ffffff;
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: rgba($color: #fafafa, $alpha: 1);
            background-clip: border-box;

            .theme_form {
                .form_item {
                    margin: 20px auto;
                    //   display: flex;
                    //   justify-content: center;
                    //   align-items: center;

                    .form_control {
                        display: block;
                        width: 100%;
                        -webkit-box-shadow: 0 0 5px 0 rgb(170 170 170 / 35%);
                        box-shadow: 0 0 5px 0 rgb(170 170 170 / 35%);
                        font-size: 12px;
                        border-radius: 20px;
                        border: 1px solid #ced4da;
                        padding: 12px 30px;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        height: 45px;
                    }
                }

                .form_button {
                    text-align: center;

                    .btn_cutom {
                        background-color: #ffffff;
                        color: $primary-color;
                        font-size: 14px;
                        border-radius: 3px;
                        text-transform: uppercase;
                        padding: 10px 25px;
                        cursor: pointer;
                        border: none;
                        background-size: 300% 100%;
                        -webkit-transition: all 0.4s ease-in-out;
                        transition: all 0.4s ease-in-out;
                        position: relative;
                        // background: darken($primary-color, 1.5%);
                        // font-weight: 100;

                        svg {
                            height: 35px;
                            left: 0;
                            position: absolute;
                            top: 0;
                            width: 100%;
                        }

                        rect {
                            fill: none;
                            stroke: $primary-color;
                            stroke-width: 2;
                            stroke-dasharray: 422, 0;
                            transition: all 0.35s linear;
                        }

                        &:hover {
                            background: rgba($primary-color, 0);
                            font-weight: 900;
                            letter-spacing: 1px;

                            rect {
                                stroke-width: 5;
                                stroke-dasharray: 15, 310;
                                stroke-dashoffset: 48;
                                transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
