<template>
  <section id="stat">
    <div class="grid-container fluid">
      <div class="grid-x grid-margin-x grid-margin-y">
        <div class="cell small-12 medium-4 large-4">
          <div class="stat_container">
            <div class="stat_numbers">
              <svg
                width="15"
                height="15"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                fill="#a460f9"
              >
                <path
                  d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                />
              </svg>
              <h2>25000</h2>
            </div>
            <div class="stat_text">
              <h3>
                Billets déjà <br />
                <strong>vendus</strong>
              </h3>
            </div>
          </div>
        </div>
        <div class="cell small-12 medium-4 large-4">
          <div class="stat_container">
            <div class="stat_numbers">
              <svg
                width="15"
                height="15"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                fill="#a460f9"
              >
                <path
                  d="M400 288h-352c-17.69 0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z"
                />
              </svg>

              <h2>48H</h2>
            </div>
            <div class="stat_text">
              <h3>
                Mise <br />
                <strong>en place</strong>
              </h3>
            </div>
          </div>
        </div>
        <div class="cell small-12 medium-4 large-4">
          <div class="stat_container">
            <div class="stat_numbers">
              <svg
                width="15"
                height="15"
                fill="#a460f9"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"
                />
              </svg>
              
              <h2>4.8/5</h2>
            </div>
            <div class="stat_text">
              <h3>
                Note <br />
                <strong>utilisateurs</strong>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Stat",
  props: {
    product: Object,
  },
};
</script> 

<style lang="scss" scoped>
@import "../assets/sass/partials/global";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

#stat {
  margin: 50px 0;
  background: #181818;
  box-shadow: 0px 0px 33px 16px #000000;

  .stat_container {
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;

    .stat_numbers {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px;

      h2 {
        color: white;
        font-family: "Poppins", sans-serif;
        font-weight: 800;
        font-size: 30px;
      }
    }
    .stat_text {
      h3 {
        color: white;
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        font-size: 14px;

        strong {
          color: #fff;
          font-size: 15px;
        }
      }
    }
  }
}
</style>