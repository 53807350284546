<template>
  <header>
    <section id="topnav" class="mobile-nav-wrapper hide-for-large">
      <div class="grid-container full">
        <div class="grid-x">
          <div class="small-12 cell">
            <!-- <nav>
              <ul class="mobile-nav">
                <li>
                  <ul class="menu">
                    <li>
                      <button @click="open = true">
                        <img src="@/assets/images/menu.png" alt="" />
                      </button>
                    </li>
                  </ul>
                </li>

                <li style="text-align: center">
                  <a href="/" class="mobile-logo">
                    <img
                      width="25%"
                      src="@/assets/images/LOGO_PAYTICK.png"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </nav> -->

            <div>
              <div class="grid-x align-middle">
                <div class="large-12 cell">
                  <div id="navbar">
                    <router-link to="/"
                      ><img
                        class="logo"
                        src="@/assets/images/LOGO_PAYTICK.png"
                        alt=""
                    /></router-link>

                    <nav>
                      <ul class="menu">
                        <li class="menu-item active-menu">
                          <button @click="open = true">
                            <img
                              class="menu-icon"
                              src="@/assets/images/menu.png"
                              alt=""
                            />
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <b-sidebar
        class="navside"
        type="is-light"
        :fullheight="fullheight"
        :fullwidth="fullwidth"
        :overlay="overlay"
        :right="right"
        v-model="open"
      >
        <div class="p-1">
          <router-link to="/"
            ><img
              @click="open = false"
              style="background: #9c49e5"
              src="@/assets/images/LOGO_PAYTICK.png"
              alt=""
          /></router-link>

          <ul class="menu">
            <li @click="open = false" class="menu-item active-menu">
              <router-link to="/">Accueil</router-link>
            </li>
            <li @click="open = false" class="menu-item">
              <router-link to="/evenements">Evènements</router-link>
            </li>
            <!-- <li class="menu-item"><a href="#work">Comment acheter ?</a></li> -->
            <!-- <li @click="open = false" class="menu-item">
              <a href="#">FAQ</a>
            </li> -->
            <li @click="open = false" class="menu-item">
              <router-link to="/contactus">Contact</router-link>
            </li>
            <li @click="open = false" class="menu-item">
              <a :href="backOfficeUrl + '/login'">Connexion</a>
            </li>
          </ul>
        </div>
      </b-sidebar>
      <!-- <div class="block">
        <b-field grouped group-multiline>
          <div class="control">
            <b-switch v-model="overlay">Overlay</b-switch>
          </div>
          <div class="control">
            <b-switch v-model="fullheight">Fullheight</b-switch>
          </div>
          <div class="control">
            <b-switch v-model="fullwidth">Fullwidth</b-switch>
          </div>
          <div class="control">
            <b-switch v-model="right">Right</b-switch>
          </div>
        </b-field>
      </div> -->
    </section>
  </header>
</template>

<script>
import {backOfficeUrl} from "@/service/const";

export default {
  name: "AppMobileNav",
  data() {
    return {
      open: false,
      overlay: true,
      fullheight: false,
      fullwidth: false,
      right: false,
      mobile: "reduce",
        backOfficeUrl: backOfficeUrl

    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/partials/global";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.p-1 {
  padding: 1em;
}

// nav {
//   background: #9c49e5;
//   box-shadow: none;
//   display: flex;
// }

ul {
  list-style: none;
}

.mobile-nav-wrapper {
  background: white;
  display: none;
  @include breakpoint(mobileonly) {
    display: block;
  }
  @include breakpoint(tablet) {
    display: block;
  }

  .mobile-nav {
    display: flex;
    // flex-shrink: 1;
    justify-content: space-between;
    align-items: center;
    .mobile-logo {
      display: inline-block;
      width: 35%;
      padding-left: 22px;
      img {
        width: 100%;
      }
    }
  }
}
.navside .b-sidebar .sidebar-content.is-fullheight {
  // background: #f0eef7 !important;
  background: red !important;
}

.menu {
  display: flex;
  flex-direction: column;
  .menu-item {
    //margin: 15px;
    a {
      padding: 20px;
      color: #9c49e5;
      transition: all 0.2s ease-in-out;
    }
  }
}

#topnav {
  background: #9c49e5;
  font-family: "Montserrat", sans-serif;
  padding: 0 5%;
  #navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    text-align: right;

    .logo {
      width: 150px;
      cursor: pointer;
      margin: 30px 0;
    }
    .menu-icon {
      width: 35px;
      cursor: pointer;
    }
    ul li {
      list-style: none;
      display: inline-block;
      margin-right: 30px;
    }

    .active-menu {
      a {
        background: white;
        color: #9c49e5 !important;
      }
    }
    .menu {
      .menu-item {
        //margin: 15px;
        a {
          padding: 20px;
          color: white;
          transition: all 0.2s ease-in-out;

          &:hover {
            background: white;
            color: #9c49e5;
          }
        }
      }
    }
  }
}
</style>
