<template>
    <section id="eventBanner">
        <AppHeroBanner />
        <div class="grid-container">
            <div class="grid-x">
                <!-- <div class="cell small-12 medium-12 large-6">
          <div class="leftcontent">
            <div class="contenu">
              <h2>Votre espace de billetterie digitale</h2>
              <h2></h2>
              <div class="feature">
                <ul>
                  <li>Simple</li>
                  <li>.</li>
                  <li>Sécurisé</li>
                  <li>.</li>
                  <li>Révolutionnaire</li>
                </ul>
                <p>
                  Quel que soit votre évènement nous avons la solution de
                  billeterie pour le gérer. Vos spectateurs pourront acheter
                  leurs tickets en ligne et les télécharger dans leur
                  smartphone.
                </p>
              </div>

              <div class="btnbeggin">
                 <router-link to="/evenements">
                  Voir les évènements
                </router-link>

                 <router-link class="btn2" to="/evenements">
                  Acheter un ticket
                </router-link>

                <router-link to="/inscription">
                  Créer un évènement
                 </router-link>
              </div>
            </div>
          </div>
        </div> -->
                <div class="cell small-12 medium-12 large-6">
                    <div class="content">
                        <div class="title">
                            <h6>Les moyens de paiement disponibles</h6>
                        </div>
                        <div class="bubble">
                            <div class="bubble_item">
                                <img
                                    src="@/assets/images/minilogo/orangemoney.png"
                                    alt=""
                                />
                            </div>
                            <div class="bubble_item">
                                <img
                                    src="@/assets/images/minilogo/wave.png"
                                    alt=""
                                />
                            </div>
                            <div class="bubble_item">
                                <img
                                    src="@/assets/images/minilogo/visa.png"
                                    alt=""
                                />
                            </div>

                            <div class="bubble_item">
                                <img
                                    src="@/assets/images/minilogo/mastercard.png"
                                    alt=""
                                />
                            </div>
                            <div class="bubble_item">
                                <img
                                    src="@/assets/images/minilogo/freemoney.png"
                                    alt=""
                                />
                            </div>
                            <div class="bubble_item">
                                <img
                                    src="@/assets/images/minilogo/emoney.png"
                                    alt=""
                                />
                            </div>
                            <div class="bubble_item">
                                <img
                                    src="@/assets/images/minilogo/wizall.png"
                                    alt=""
                                />
                            </div>

                            <div class="bubble_item">
                                <img
                                    src="@/assets/images/minilogo/moov.png"
                                    alt=""
                                />
                            </div>
                            <div class="bubble_item">
                                <img
                                    src="@/assets/images/minilogo/cash.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cell small-12 medium-12 large-6">
                    <AppBanner />
                </div>
            </div>
            <Appstat />
            <app-pricing />
        </div>
        <br />
        <!-- <AppFeature /> -->
    </section>
</template>

<script>
// import AppFeature from "@/components/AppFeature.vue";
import AppBanner from "@/components/AppBanner.vue";
import Appstat from "@/components/Appstat.vue";
import AppHeroBanner from "@/components/AppHeroBanner.vue";
import AppPricing from '../components/AppPricing.vue';

export default {
  name: "Accueil",


  components: {
    // AppFeature,
    AppBanner,
    Appstat,
    AppHeroBanner,
    AppPricing
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/partials/global";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

#eventBanner {
    position: relative;
    margin-top: 20px;
    //min-height: 81.9vh;
    min-height: 100vh;

    .leftcontent {
        padding: 10px 15%;
        flex-basis: 40%;
        //margin-left: 20px;
        text-align: start;
        background: $secondary-color;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(mobileonly) {
            text-align: start;
            margin: 0;
            padding: 10px 6%;
        }

        .contenu {
            h2 {
                font-size: 40px;
                color: #000;
                font-weight: 600;
                letter-spacing: -0.0625rem;
                margin-bottom: 0.2rem;
                // font-size: 40px;
                margin-top: 5px;
                text-align: center;
            }

            .feature {
                ul {
                    //²width: 80%;
                    margin: 0 0 16px 0;
                    list-style: none;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    font-family: "Mochiy Pop P One", "Poppins", "Roboto",
                        sans-serif;
                    color: $primary-color;
                    font-size: 1.125rem;
                    font-weight: 400;
                    line-height: 1.6;
                    text-transform: none;

                    // li {
                    //   &::after {
                    //     content: ".";
                    //     display: inline-block;
                    //     justify-content: space-between;
                    //   }
                    // }
                }
                p {
                    font-size: 16px;
                    font-weight: 100;
                    text-align: justify;
                    flex-wrap: none;
                }
            }

            .btnbeggin {
                display: flex;
                justify-content: space-between;

                @media only screen and (max-width: 700px) {
                    display: flex;
                    flex-direction: column;
                }

                a {
                    border-radius: 6px;
                    border: 1px solid $primary-color;
                    background-color: $primary-color;
                    color: #ffffff;
                    font-size: 12px;
                    font-weight: bold;
                    padding: 12px 15px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    transition: transform 80ms ease-in;
                    margin: 5px 10px 5px 0;
                    cursor: pointer;
                    text-align: center;

                    // a {
                    //   color: #ffffff;
                    // }
                }
                .btn2 {
                    background-color: $secondary-color;

                    color: $primary-color;
                }
            }
        }
    }

    .content {
        margin-bottom: 20px;
        .title {
            text-align: center;
            h6 {
                color: #ffffff;
                font-size: 25px;
            }
        }
        .bubble {
            display: grid;
            justify-content: center;
            gap: 2.0em;
            grid-template-rows: 1fr 1fr 1fr;
            grid-template-columns: repeat(3, 100px);

            .bubble_item {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 70%;
                }
            }
        }
    }

    .ticket {
        margin: 50px;
        .ticketpartone {
            background: red;
            height: 100%;

            border-right: 0.33em dashed #fff;
            clip-path: polygon(
                10% 0,
                90% 0,
                100% 13%,
                100% 87%,
                90% 100%,
                10% 100%,
                0 87%,
                0 13%
            );
            .statistic {
                padding: 20px;
                .stat {
                    // display: flex;
                    // justify-content: center;
                    // align-items: center;

                    ul {
                        li {
                            list-style: none;

                            &::before {
                                content: "";
                                display: inline-block;
                                height: 50%;
                                width: 50%;
                                background-image: url("../assets/images/chrono.png");
                            }
                        }
                    }
                    p {
                        font-size: 20px;
                    }
                }
            }
        }
        .ticketparttwo {
            background: rebeccapurple;
            height: 100%;
            width: 100%;

            clip-path: polygon(
                10% 0,
                90% 0,
                100% 0,
                100% 100%,
                90% 100%,
                10% 100%,
                0 87%,
                0 13%
            );
        }
        .ticketpartthree {
            background: blue;
            height: 100%;
            width: 100%;

            clip-path: polygon(
                0 0,
                90% 0,
                100% 13%,
                100% 87%,
                90% 100%,
                0 100%,
                0% 70%,
                0% 30%
            );
        }

        // .cardRight {
        //   // width: 6.5em;
        //   border-left: 0.28em dashed #fff;
        //   border-top-right-radius: 8px;
        //   border-bottom-right-radius: 8px;
        //   &:before,
        //   &:after {
        //     content: "";

        //     position: absolute;
        //     display: block;
        //     width: 0.9em;
        //     height: 0.9em;
        //     background: red;
        //     border-radius: 50%;
        //     left: -0.5em;
        //   }
        //   &:before {
        //     top: -0.4em;
        //   }
        //   &:after {
        //     bottom: -0.4em;
        //   }
        // }
    }
}
</style>
