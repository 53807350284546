import axios from 'axios';
import {apiUrl} from "@/service/const";


export const httpClient = axios.create({
    baseURL: apiUrl,
    timeout: 30000,
    headers: {

    }
});

