<template>
  <section id="herobanner">
    <!-- <div class="circle"></div> -->

    <div class="home_decoration show-for-large">
      <div class="home-circle1">
        <img src="@/assets/images/cone.png" alt="circle1" />
      </div>
      <div class="home-circle2">
        <img src="@/assets/images/ctwo.png" alt="circle2" />
      </div>
      <div class="home-circle3">
        <img src="@/assets/images/cthree.png" alt="circle3" />
      </div>
    </div>

    <div class="grid-container">
      <div class="grid-x grid-margin-x grid-margin-y">
        <div class="cell small-12 medium-12 large-6">
          <div class="left_content">
            <div class="leftcontent">
              <div class="contenu">
                <h2>Votre espace de billetterie digitale</h2>
                <div class="feature">
                  <ul>
                    <li>Simple</li>
                    <li>.</li>
                    <li>Sécurisé</li>
                    <li>.</li>
                    <li>Révolutionnaire</li>
                  </ul>
                  <p>
                    Quel que soit votre évènement nous avons la solution de
                    billeterie pour le gérer. Vos spectateurs pourront acheter
                    leurs tickets en ligne et les télécharger dans leur
                    smartphone.
                  </p>
                </div>

                <div class="btnbeggin">
                  <!-- <button class="btn1"> -->
                  <router-link to="/evenements">
                    Voir les évènements
                  </router-link>
                  <!-- </button> -->

                  <!-- <button class="btn2"> -->
                  <router-link class="btn2" to="/evenements">
                    Acheter un ticket
                  </router-link>
                  <!-- </button> -->

                  <a :href="backOfficeUrl + '/login'">
                    Créer un évènement
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cell small-12 medium-12 large-6">
          <div class="right_content">
            <img src="@/assets/images/payticksmartphone.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- <div>
      <svg
        class="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shape-rendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g class="parallax">
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="0"
            fill="rgba(24,24,24,0.7)"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="3"
            fill="rgba(24,024,0,0.5)"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="5"
            fill="rgba(24,24,24,0.3)"
          />
          <use xlink:href="#gentle-wave" x="48" y="7" fill="#181818" />
        </g>
      </svg>
    </div> -->
  </section>
</template>

<script>


import {backOfficeUrl} from "@/service/const";

export default {
  name: "HeroBanner",
  props: {
    product: Object,
  },
    data() {
        return {
            backOfficeUrl: backOfficeUrl
        };
    },
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/partials/global";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

#herobanner {
  //   height: 85vh;
  //   background: red;
  // position: relative;
  // margin-bottom: 50px;
  // z-index: 2;

  // background-image: url(@/assets/images/payticksmartphone.png);
  width: 100vw;
  // height: 100vh !important;
  background-repeat: no-repeat;
  margin-top: 0;
  display: block;
  background-size: cover;
  background-position: bottom;
  position: relative;

  .circle {
    background: rgba($color: $primary-color, $alpha: 0.7);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    clip-path: circle(200px at right 300px);
    z-index: -1;
  }

  .home-circle1 {
    position: absolute;
    top: 50px;
    left: 75px;
    animation: float 4s ease-in-out infinite;
    animation-delay: 0.5s;
    z-index: 10;

    // @include breakpoint(laptop) {
    //   top: auto;
    //   left: 65px;
    //   bottom: 0;
    // }
  }
  .home-circle2 {
    position: absolute;
    top: 150px;
    right: 25px;
    animation: float 4.5s ease-in-out infinite;
    animation-delay: 0.5s;
  }
  .home-circle3 {
    position: absolute;
    top: 235px;
    right: 55px;
    animation: float 5s ease-in-out infinite;
    animation-delay: 0.5s;
  }

  @keyframes float {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0px);
    }
  }

  /*.home_decoration {
    .home-circle1 {
      position: absolute;
      top: 185px;
      left: 75px;
    }
    .home-circle2 {
      position: absolute;
      top: 150px;
      right: 25px;
    }
    .home-circle3 {
      position: absolute;
      top: 235px;
      right: 55px;
    }
  }*/

  .left_content {
    height: 100%;
    color: #ffffff;
    z-index: 20;
    position: relative;
    .leftcontent {
      padding: 0px 10%;
      flex-basis: 40%;
      //margin-left: 20px;
      text-align: start;
      //   background: $secondary-color;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      @include breakpoint(mobileonly) {
        text-align: start;
        margin: 0;
        padding: 10px 6%;
      }

      .contenu {
        h2 {
          font-size: 50px;
          color: #fff;
          font-weight: 600;
          letter-spacing: -0.0625rem;
          margin-bottom: 0.2rem;
          // font-size: 40px;
          margin-top: 5px;
          text-align: center;
        }

        .feature {
          ul {
            //²width: 80%;
            margin: 0 0 16px 0;
            list-style: none;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            font-family: "Mochiy Pop P One", "Poppins", "Roboto", sans-serif;
            color: $primary-color;
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 1.6;
            text-transform: none;

            // li {
            //   &::after {
            //     content: ".";
            //     display: inline-block;
            //     justify-content: space-between;
            //   }
            // }
          }
          p {
            font-size: 16px;
            font-weight: 100;
            text-align: justify;
            flex-wrap: none;
          }
        }

        .btnbeggin {
          display: flex;
          justify-content: space-between;

          @media only screen and (max-width: 700px) {
            display: flex;
            flex-direction: column;
          }

          a {
            border-radius: 6px;
            border: 1px solid $primary-color;
            background-color: $primary-color;
            color: #ffffff;
            font-size: 12px;
            font-weight: bold;
            padding: 12px 15px;
            letter-spacing: 1px;
            text-transform: uppercase;
            transition: transform 80ms ease-in;
            margin: 5px 10px 5px 0;
            cursor: pointer;
            text-align: center;

            // a {
            //   color: #ffffff;
            // }
          }
          .btn2 {
            background-color: $secondary-color;

            color: $primary-color;
          }
        }
      }
    }
  }

  .right_content {
    position: relative;
    animation: float 3s ease-in-out infinite;
    @keyframes float {
      0% {
        transform: translateY(0px);
      }

      50% {
        transform: translateY(-20px);
      }

      100% {
        transform: translateY(0px);
      }
    }
    img {
      z-index: 100000;
    }
  }

  .waves {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
  }

  .content {
    position: relative;
    height: 20vh;
    text-align: center;
    background-color: white;
  }

  /* Animation */

  .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }
  /*Shrinking for mobile*/
  @media (max-width: 768px) {
    .waves {
      height: 40px;
      min-height: 40px;
    }
    .content {
      height: 30vh;
    }
    h1 {
      font-size: 24px;
    }
  }
}
</style>
